
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import headerBanner from '../../home/banner.vue'

export default {
    name: 'monitor',
    components: {
        homeTitlepc,
        headerBanner,
        
    },

    mounted () {
     
    },

    created(){
       

    },

    methods: {
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }


        }
      

    },

    data () {
        return {
            config,
            works1:[
                {
                    img:require('./images/q1.png'),
                    name:'全品',
                    show:'图文影音全品类综合保障版权方权益覆盖领域包括内容付费、动漫娱乐、影视音频、图片摄影等'
                },
                {
                    img:require('./images/q2.png'),
                    name:'全网',
                    show:'可对100000+网站/app/新媒体平台/电商/网盘等渠道进行有效监控'
                },
                {
                    img:require('./images/q3.png'),
                    name:'全时',
                    show:'7*24h全年无休快速接入和自动监测'
                },
                {
                    img:require('./images/q4.png'),
                    name:'按需',
                    show:'按客户需求可采用不同监测方案组合，保障效果且客户成本可控最优'
                },
                {
                    img:require('./images/q5.png'),
                    name:'可视',
                    show:'监测结果数据可视快照存档了然于心'
                },
            ],
            works2:[
                {
                    img:require('./images/s1.png'),
                    name:'网文小说',
                    show:'对网文长篇小说进行监测，发现盗文行为线索'
                },
                {
                    img:require('./images/s2.png'),
                    name:'付费课程',
                    show:'对音频、视频课程进行监测，监测全网盗版课程侵权痕迹'
                },
                {
                    img:require('./images/s3.png'),
                    name:'短视频',
                    show:'监测短视频作品搬运、复制等侵权行为，已监测覆盖抖音、快手等平台'
                },
                {
                    img:require('./images/s4.png'),
                    name:'动漫娱乐',
                    show:'对IP形象、漫画、动漫等侵权监测'
                },
                {
                    img:require('./images/s5.png'),
                    name:'其它类型',
                    show:'支持新闻资讯、影视综艺等其它作品进行监测'
                },
            ],
           
            
        }
    }
}
